body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


form label {
  color: #2d2d2d;
  font-size: 12px;
  font-weight: 500;
}

.errorText{
  font-size: 11px;
  padding: 3px 0;
  color: #e4002b;
}

.inputField {
  padding: 6px 10px;
  margin-top: 5px;
  border: 0.5px solid #d1d1d1;
  border-radius: 0;
  font-size: 14px;
  width: 95%;
  transition: border-color 0.3s;
  -webkit-box-shadow:  1px -1px 1px 1px #d1d1d1;
  box-shadow: 1px -1px 1px 1px #d1d1d1;
}

.inputField:focus {
  border-color: #B39132;
  outline: none;
}

.submitButton {
  background-color: #0A3EA4;
  color: #fff;
  border: none;
  border-radius: 50px;
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  font-weight: 500;
  user-select: none;
  transition: background-color 0.3s;
  -webkit-box-shadow: 4px -3px -2px -1px #d1d1d1; 
  box-shadow: 4px -3px -2px -1px #d1d1d1;
}

.submitButton:hover {
  background-color: #B39132;
}

.submitButton:disabled{
  background-color: #d7d7d8;
  cursor:progress;
}

.rounded-checkbox {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid gray;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.rounded-checkbox:checked {
  appearance: auto;
  clip-path: circle(50% at 50% 50%);
  background-color: green;
}